import React from "react"
import styles from "./styles.module.css"
import PlanSideBar from "../../shared/planSideBar";
import CurrentPlanInformation from "../../shared/currentPlanInformation";
import { viewPlan } from "../../services/customerInfo";
import { getUser } from "../../services/auth";
import { navigate } from "gatsby";
import PricingTable from "../pricingTable";
import { System_Type } from "../../common/constants";

class Plan extends React.Component {
    constructor(props) {
        super(props);
        const user = getUser();
        const token = user && user.access_token;
        const isTemp = user && user.id ==='TEMP';

        this.state = {
            planDetail:true,
            plan: null,
            token,
            isTemp
        };

    }

    componentDidMount() {
        this.getPlan(this.state.token);
    }

    getPlan = (token) => {
        viewPlan(token).then(({response,error}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
            } else {
                this.setState({plan: response});
            }
        })
    }

    onActionClick = (actionValid) => {
        if (actionValid) {
            navigate('/account/order/product');
        }
    }

    onTryClick = (productId) => {
        navigate('/account/order/product',{state: {productId}});
    }

    render() {
        var activeProduct;
        var isDesktop = false;
        if (this.state.plan) {
            isDesktop = this.state.plan.SystemType == System_Type.DESKTOP;
            if (isDesktop) {
                activeProduct = this.state.plan.ProductId > 103 ? 103 : this.state.plan.ProductId;
            } else {
                activeProduct = this.state.plan.ProductId > 303 ? 303 : this.state.plan.ProductId;
            }
        }
        return (
        <>
            <div className={styles.plan_section}>
                <div className="container-fluid">
                    <div className="row plan-grey-background">
                    <PlanSideBar isTemp={this.state.isTemp} page={"Plan"}/>
                        <div className={["col-lg-10", "col-md-12", "right_section"].join(" ")}>
                            <div className={[styles.mpr_plan_section,"mpr_plan_section","p-lg-5","p-md-3"].join(' ')}>
                                <div className={["mpr_c_plan_title", "mt-5", "pb-5"].join(" ")}>
                                    <h2>My Current Plan</h2>
                                </div>
                                {
                                    this.state.plan &&
                                    <CurrentPlanInformation planInformation={this.state.plan} showActionButtons={true} onActionClick={this.onActionClick}/>
                                }
                                {/* {
                                    this.state.plan && this.state.plan.IsLegacyProduct &&
                                    <PricingTable isPlanPage={true} isDesktop={isDesktop} activeProduct={activeProduct} expiring={this.state.plan && this.state.plan.RenewalEligible} onTryClick={this.onTryClick} IsExpired={this.state.plan && this.state.plan.IsExpired}/>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default Plan