import React from "react"
import Plan from "../../components/plan";
import PrivateRoute from "../../components/privateRoute";

class Page extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/plan" component={Plan} location={this.props.location} showFooter={false} greyBackground={true} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "My Plan" 
}

export default Page